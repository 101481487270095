import { Component, OnInit, HostListener } from '@angular/core';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})


export class HomeComponent implements OnInit {
  
  constructor(
    private viewPortScroller: ViewportScroller
  ) { 
    this.viewPortScroller.scrollToPosition([0, 0]);
  }

  ngOnInit() {
  }
  
  
}
