import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Angulartics2Module } from 'angulartics2';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { HttpClientModule } from '@angular/common/http'
import { APP_ROUTING } from "./app.routes";


import { AppComponent } from './app.component';
import { HeaderComponent } from './components/shared/header/header.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { HomeComponent } from './components/home/home.component';
import { AboutComponent } from './components/about/about.component';
import { ContactoComponent } from './components/contacto/contacto.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { GtagModule } from 'angular-gtag';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    AboutComponent,
    ContactoComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    APP_ROUTING,
    FormsModule,
    GtagModule.forRoot({trackingId: 'UA-152393727-1', trackPageviews: true}),
    ReactiveFormsModule,
    Angulartics2Module.forRoot({
      pageTracking: {
        clearHash: true
      }
    })
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
