import { Component, OnInit, NgZone } from '@angular/core';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})


export class HeaderComponent implements OnInit {
  
  public open: boolean = false;

  isShrunk: boolean = false;

  constructor(zone: NgZone) {
    window.onscroll = () => {
      zone.run(() => {
        if(window.pageYOffset >= 100) {
             this.isShrunk = true;
        } else {
             this.isShrunk = false;
        }
      });
    }
  }

 

  ngOnInit() {
   }

  displayMenu() {
    // var x = document.getElementById("links");
    // if (x.style.display === "block") {
    //     x.style.display = "none";
    // } else {
    //     x.style.display = "block";
    // }
    this.open = !this.open;
  }

  scrollToTop() {
    (function smoothscroll() {
      var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - (currentScroll / 5));
      }
    })();
    this.open=false;
  }

}
