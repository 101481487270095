import { Component, OnInit } from '@angular/core';
import { Gtag } from 'angular-gtag';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  private gtaglocal: Gtag = null;
  constructor(gtag: Gtag) {
    this.gtaglocal = gtag;
   }

  ngOnInit() {
  }

  /*Gtag events */
  public gtagEvent(name) {
    console.log("gtag event");
    this.gtaglocal.event(name, {
      event_category: "contacto",
      event_label: "contacto",
      value: name
    });
  }

}
