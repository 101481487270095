import { Component, OnInit, HostListener } from '@angular/core';
import { ViewportScroller } from "@angular/common";

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  constructor(
    private viewPortScroller: ViewportScroller
  ) { 
    this.viewPortScroller.scrollToPosition([0, 0]);
  }

  ngOnInit() {
  }
  

}
