import { Component } from '@angular/core';
import { Gtag } from 'angular-gtag';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})

export class AppComponent {
  title = 'uroboros-ent';
  constructor(angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics, gtag:Gtag) {
    angulartics2GoogleAnalytics.startTracking();
  }
}

//    constructor(private router: Router) {
  //      this.router.events.subscribe(event => {
    //       if (event instanceof NavigationEnd) {
      //         (<any>window).ga('set', 'page', event.urlAfterRedirects);
      //         (<any>window).ga('send', 'pageview');
      //       }
      //     });
      //   }
      //  }
      
      
      // export class AppComponent {
      //   constructor(public router: Router){   
      //       this.router.events.subscribe(event => {
      //          if(event instanceof NavigationEnd){
      //              gtag('config', 'UA-152060757-1', 
      //                    {
      //                      'page_path': event.urlAfterRedirects
      //                    }
      //                   );
      //           }
      //        }
      //     )}
      // }