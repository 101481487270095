import { Component, OnInit, HostListener } from '@angular/core';
import { ViewportScroller } from "@angular/common";
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.css']
})
export class ContactoComponent implements OnInit {

  private url: string = "https://uroborosentertainment.com/mail.php";

  public contactoForm: FormGroup;
  public mensaje: string = null;

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder
  ) {
    this.contactoForm = this.formBuilder.group({
      nombre: [null, [Validators.required, Validators.maxLength(100)]],
      asunto: [null, [Validators.required, Validators.maxLength(100)]],
      correo: [null, [Validators.required, Validators.email, Validators.maxLength(100)]],
      mensaje: [null, [Validators.required, Validators.maxLength(250)]]
    });
  }

  ngOnInit() {
  }

  public async enviarCorreo(): Promise<void> {

    if (this.contactoForm.invalid) {
      this.mensaje = 'Debe completar el formulario para continuar';
      return;
    }

    const valores = this.contactoForm.value;

    const formData = new FormData();
    formData.append('nombre', valores.nombre);
    formData.append('asunto', valores.asunto);
    formData.append('correo', valores.correo);
    formData.append('mensaje', valores.mensaje);
    const response = await this.http.post(this.url, formData, { observe: 'response' }).toPromise();

    if (response.status === 200) {
      this.mensaje = 'Se ha envíado tu mensaje, espera pronta respuesta. ¡Gracias!';
    }
  }
}
